var render = function render(){var _vm=this,_c=_vm._self._c;return _c('dl',{staticClass:"dlImgSlide"},[_c('dt',[_c('div',{staticClass:"swiper-container"},[_c('div',{staticClass:"swiper-wrapper"},[_c('div',{staticClass:"swiper-slide"},[_c('img',{attrs:{"src":_vm.article.files,"alt":""}})])])])]),_c('dd',[(_vm.showTitle)?_c('h3',[_vm._v(_vm._s(_vm.article.title))]):_vm._e(),_c('p',[_vm._v(" "+_vm._s(_vm.article.des)+" ")]),_c('a',{on:{"click":function($event){return _vm.$router.push({
          path: '/portalDetails',
          query: {
            id: _vm.article.id,
            activeCode: _vm.activeCode,
          },
        })}}},[_vm._v(" 查看更多 > ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }