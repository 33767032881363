<template>
  <dl class="dlImgSlide">
    <dt>
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img :src="article.files" alt="" />
          </div>
        </div>
      </div>
      <!-- <div class="direction">
        <span class="s_prev"><i></i></span>
        <span class="s_next"><i></i></span>
      </div> -->
    </dt>
    <dd>
      <h3 v-if="showTitle">{{ article.title }}</h3>
      <p>
        {{ article.des }}
      </p>
      <a
        @click="
          $router.push({
            path: '/portalDetails',
            query: {
              id: article.id,
              activeCode,
            },
          })
        "
      >
        查看更多 >
      </a>
    </dd>
  </dl>
</template>

<script>
export default {
  props: {
    activeCode: { type: String, default: 'yizhongkecheng' },
    showTitle: { type: Boolean, default: true },
    article: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    new Swiper('.dlImgSlide .swiper-container', {
      loop: false,
      slidesPerView: 1, // 一行显示3个
      spaceBetween: 30,
      navigation: {
        nextEl: '.dlImgSlide .s_next',
        prevEl: '.dlImgSlide .s_prev',
      },
    })
  }
}
</script>

<style lang="less" scoped>
.dlImgSlide {
  dt {
    position:relative;
    .direction {
      bottom: 0;
    }
  }
}
</style>
