// 亦中日子
<template>
  <div class="campusLife">
    <TabPageLayout activeCode="xiaoyuanshenghuo">
      <div class="container internationalCourse">
        <div v-html="typeBz" />

        <div class="div_tit" v-for="(block, index) in blocks" :key="block.id">
          <h2>{{ block.title }}</h2>
          <div
            v-if="block?.code ? block?.code == 'CampusCultureDay' : false"
            class="div_mod7 specialEvents"
          >
            <SwiperContainer2 :typeId="block.id + ''" />
          </div>

          <div v-else>
            <div v-if="blockArticles[index]?.length">
              <ArticleCard2
                v-for="(article, subIndex) in blockArticles[index]"
                :key="article.id"
                :article="article"
                :showTitle="false"
                activeCode="xiaoyuanshenghuo"
                :class="{
                  dlSp: (subIndex + 1) % 2 === 0,
                }"
              />
            </div>
          </div>
        </div>
      </div>
    </TabPageLayout>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TabPageLayout from './components/TabPageLayout.vue'
import { useGetCategories } from './utils/useGetCategories'
import portalApi from '../../../../src/lvya-common/src/api/eos/portal'
import ArticleCard2 from './components/ArticleCard2.vue'
import DoTime from '../../../../src/lvya-common/src/utils/Time/DoTime'
import SwiperContainer2 from './components/SwiperContainer2.vue'

export default {
  components: {
    TabPageLayout,
    ArticleCard2,
    SwiperContainer2,
  },
  computed: {
    ...mapState({
      nav: (state) => state.yzsyStore.nav,
    }),
    typeId() {
      return this.nav?.find((item) => item.code === 'xiaoyuanshenghuo')?.stypes[0]?.id || undefined
    },
    typeBz() {
      return this.nav.find((item) => item.code === 'xiaoyuanshenghuo')?.stypes[0]?.bz
    },
    blocksLength() {
      return this.blocks.length
    }
  },
  data() {
    return {
      blocks: [],
      blockArticles: [],
    }
  },
  methods: {
    formatDate(time) {
      return DoTime.dateFormat('MM月dd日', time)
    },
    async getBlocks() {
      const categories = await useGetCategories(this.typeId)
      this.blocks = categories.list
      await this.getArticles()
    },
    async getArticles() {
      // const portalSchoolInfo = JSON.parse(localStorage.getItem('portalSchoolInfo'))
      this.blockArticles = []
      for (const block of this.blocks) {
        let reqData = {
          noSchoolId: true,
          schoolId: '7',
          pageNum: 1,
          pageSize: 999,
          typeId: block.id,
          isContent: 'Y',
          applyState: 'Y',
        }
        let resData = await portalApi.getArtsByPage(reqData)
        this.blockArticles.push(resData?.list || [])
      }
    },
  },
  async mounted() {
    await this.getBlocks()
    // await this.getArticles()
  },
}
</script>

<style lang="less" scoped>
@import "./style/common.css";
@import "./style/global.css";
@import "./style/campusLife.css";
</style>

